<template>
  <div style="background: #e35049;color: #000; min-height: 100%">
    <van-nav-bar title="卡券商城" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div class="hed_lis">
      <div class="hhcc" @click="qiehuan(0)" :class="active == '0'?'acitv':''">现金券</div>
      <div class="hhcc" @click="qiehuan(1)" :class="active == '1'?'acitv':''">折扣券</div>
      <div class="hhcc" @click="qiehuan(2)" :class="active == '2'?'acitv':''">单次券</div>
      <div class="hhcc" @click="qiehuan(3)" :class="active == '3'?'acitv':''">礼包券</div>
      <div class="hhcc" @click="qiehuan(4)" :class="active == '4'?'acitv':''">充值卡</div>
    </div>
    <div class="main" style="background: #e35049;">
      <div class="list" v-for="(item,idx) in form.cashList" :key="idx" v-if="active == '0'">
        <img class="yh_img" src="../../assets/img/tkbeijin.png" />
        <div class="list_a">
          <div class="left letzuo" v-if="item.type != 5">
            <div style="margin-top: 40px;font-weight: bold;" v-if="item.type == 1">
              <span>￥</span>
              <span style="font-size: 27px;">{{item.discountPrice}}</span>
            </div>
            <div style="margin-top: 40px;font-weight: bold;" v-if="item.type == 2">
              <span style="font-size: 27px;">{{item.discountPrice}}折</span>
            </div>
            <div style="line-height: 20px;margin-top:5px;">
              <span v-if="item.type == 1">代金券</span>
              <span v-if="item.type == 2">折扣券</span>
            </div>
          </div>
          <div class="left letzuo" v-else>
            <div style="margin-top: 40px;font-weight: bold;">
              <span>￥</span>
              <span style="font-size: 27px;">{{item.sellPrice}}</span>
            </div>
            <div style="line-height: 35rpx;margin-top:10rpx;">售价</div>
          </div>
          <div class="right ris">
            <div class="ris_a">
              <span class="left h1">{{item.couponName}}</span>
              <span class="right cunsts">1张</span>
            </div>
            <div
              class="ris_a fsz"
              style="margin-top: 15px;color: #656565;"
            >有效期:{{item.createdDate.substring(0,10)}}~{{item.lastModifiedDate.substring(0,10)}}</div>
            <div class="ris_a fsz" style="margin-top: 2px;">
              <span class="left" @click="tixiangqin(item)">查看详情</span>
              <div class="right riss">
                <div class="left" style="position: relative;margin-top: 2px;">
                  <img class="kajin" src="../../assets/img/kajin.png" alt />
                  <span class="jinse">{{item.sellPrice}}</span>
                </div>
                <div class="jianes" @click="addjia(item)" v-if="item.buyCounts < 1">
                  <img src="../../assets/img/jiaadd.png" alt />
                </div>
                <van-stepper
                  v-else
                  class="my-swipe"
                  v-model="item.buyCounts"
                  @plus="addjia(item)"
                  @minus="minusCount(item)"
                  @overlimit="minusCount(item)"
                  theme="round"
                  button-size="22"
                  disable-input
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list" v-for="(item,idx) in form.discList" :key="idx" v-if="active == '1'">
        <img class="yh_img" src="../../assets/img/tkbeijin.png" />
        <div class="list_a">
          <div class="left letzuo" v-if="item.type != 5">
            <div style="margin-top: 40px;font-weight: bold;" v-if="item.type == 1">
              <span>￥</span>
              <span style="font-size: 27px;">{{item.discountPrice}}</span>
            </div>
            <div style="margin-top: 40px;font-weight: bold;" v-if="item.type == 2">
              <span style="font-size: 27px;">{{item.discountPrice}}折</span>
            </div>
            <div style="line-height: 20px;margin-top:5px;">
              <span v-if="item.type == 1">代金券</span>
              <span v-if="item.type == 2">折扣券</span>
            </div>
          </div>
          <div class="left letzuo" v-else>
            <div style="margin-top: 40px;font-weight: bold;">
              <span>￥</span>
              <span style="font-size: 27px;">{{item.sellPrice}}</span>
            </div>
            <div style="line-height: 35rpx;margin-top:10rpx;">售价</div>
          </div>
          <div class="right ris">
            <div class="ris_a">
              <span class="left h1">{{item.couponName}}</span>
              <span class="right cunsts">1张</span>
            </div>
            <div
              class="ris_a fsz"
              style="margin-top: 15px;color: #656565;"
            >有效期:{{item.createdDate.substring(0,10)}}~{{item.lastModifiedDate.substring(0,10)}}</div>
            <div class="ris_a fsz" style="margin-top: 2px;">
              <span class="left" @click="tixiangqin(item)">查看详情</span>
              <div class="right riss">
                <div class="left" style="position: relative;margin-top: 2px;">
                  <img class="kajin" src="../../assets/img/kajin.png" alt />
                  <span class="jinse">{{item.sellPrice}}</span>
                </div>
                <div class="jianes" @click="addjia(item)" v-if="item.buyCounts < 1">
                  <img src="../../assets/img/jiaadd.png" alt />
                </div>
                <van-stepper
                  v-else
                  class="my-swipe"
                  v-model="item.buyCounts"
                  @plus="addjia(item)"
                  @minus="minusCount(item)"
                  @overlimit="minusCount(item)"
                  theme="round"
                  button-size="22"
                  disable-input
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list" v-for="(item,idx) in form.onceList" :key="idx" v-if="active == '2'">
        <img class="yh_img" src="../../assets/img/tkbeijin.png" />
        <div class="list_a">
          <div class="left letzuo">
            <div style="margin-top: 40px;font-weight: bold;">
              <span>￥</span>
              <span style="font-size: 27px;">{{item.discountPrice}}</span>
            </div>
            <div style="line-height: 35rpx;margin-top:10rpx;">单次券</div>
          </div>
          <div class="right ris">
            <div class="ris_a">
              <span class="left h1">{{item.couponName}}</span>
              <span class="right cunsts">1张</span>
            </div>
            <div
              class="ris_a fsz"
              style="margin-top: 15px;color: #656565;"
            >有效期:{{item.createdDate.substring(0,10)}}~{{item.lastModifiedDate.substring(0,10)}}</div>
            <div class="ris_a fsz" style="margin-top: 2px;">
              <span class="left" @click="tixiangqin(item)">查看详情</span>
              <div class="right riss">
                <div class="left" style="position: relative;margin-top: 2px;">
                  <img class="kajin" src="../../assets/img/kajin.png" alt />
                  <span class="jinse">{{item.sellPrice}}</span>
                </div>
                <div class="jianes" @click="addjia(item)" v-if="item.buyCounts < 1">
                  <img src="../../assets/img/jiaadd.png" alt />
                </div>
                <van-stepper
                  v-else
                  class="my-swipe"
                  v-model="item.buyCounts"
                  @plus="addjia(item)"
                  @minus="minusCount(item)"
                  @overlimit="minusCount(item)"
                  theme="round"
                  button-size="22"
                  disable-input
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list" v-for="(item,idx) in form.comboList" :key="idx" v-if="active == '3'">
        <img class="yh_img" src="../../assets/img/tkbeijin.png" />
        <div class="list_a">
          <div class="left letzuo">
            <div style="margin-top: 40px;font-weight: bold;">
              <span>￥</span>
              <span style="font-size: 27px;">{{item.discountPrice}}</span>
            </div>
            <div style="line-height: 35rpx;margin-top:10rpx;">礼包券</div>
          </div>
          <div class="right ris">
            <div class="ris_a">
              <span class="left h1">{{item.couponName}}</span>
              <span class="right cunsts">1张</span>
            </div>
            <div
              class="ris_a fsz"
              style="margin-top: 15px;color: #656565;"
            >有效期:{{item.createdDate.substring(0,10)}}~{{item.lastModifiedDate.substring(0,10)}}</div>
            <div class="ris_a fsz" style="margin-top: 2px;">
              <span class="left" @click="tixiangqin(item)">查看详情</span>
              <div class="right riss">
                <div class="left" style="position: relative;margin-top: 2px;">
                  <img class="kajin" src="../../assets/img/kajin.png" alt />
                  <span class="jinse">{{item.sellPrice}}</span>
                </div>
                <div class="jianes" @click="addjia(item)" v-if="item.buyCounts < 1">
                  <img src="../../assets/img/jiaadd.png" alt />
                </div>
                <van-stepper
                  v-else
                  class="my-swipe"
                  v-model="item.buyCounts"
                  @plus="addjia(item)"
                  @minus="minusCount(item)"
                  @overlimit="minusCount(item)"
                  theme="round"
                  button-size="22"
                  disable-input
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  <div class="list" v-for="(item,idx) in form.memberStoreList" :key="idx" v-if="active == '4'">
        <img class="yh_img" src="../../assets/img/tkbeijin.png" />
        <div class="list_a">
          <div class="left letzuo">
            <div style="margin-top: 40px;font-weight: bold;">
              <span>￥</span>
              <span style="font-size: 27px;">{{item.discountPrice}}</span>
            </div>
            <div style="line-height: 35rpx;margin-top:10rpx;">储值金</div>
          </div>
          <div class="right ris">
            <div class="ris_a">
              <span class="left h1">{{item.couponName}}</span>
              <span class="right cunsts">1张</span>
            </div>
            <div
              class="ris_a fsz"
              style="margin-top: 15px;color: #656565;"
            >有效期:{{item.createdDate.substring(0,10)}}~{{item.lastModifiedDate.substring(0,10)}}</div>
            <div class="ris_a fsz" style="margin-top: 2px;">
              <span class="left" @click="tixiangqin(item)">查看详情</span>
              <div class="right riss">
                <div class="left" style="position: relative;margin-top: 2px;">
                  <img class="kajin" src="../../assets/img/kajin.png" alt />
                  <span class="jinse">{{item.sellPrice}}</span>
                </div>
                <div class="jianes" @click="addjia(item)" v-if="item.buyCounts < 1">
                  <img src="../../assets/img/jiaadd.png" alt />
                </div>
                <van-stepper
                  v-else
                  class="my-swipe"
                  v-model="item.buyCounts"
                  @plus="addjia(item)"
                  @minus="minusCount(item)"
                  @overlimit="minusCount(item)"
                  theme="round"
                  button-size="22"
                  disable-input
                />
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div style="height: 60px;"></div>
    <div class="botts">
      <div class="gou">
        <div class="gou2">
          <img class="gouwu" @click="show =true" src="../../assets/img/gouwu.png" alt />
          <span class="shul" v-if="num > 0">{{num}}</span>
        </div>
      </div>

      <div class="bost">
        <div class="liji" @click="pay" v-preventReClick>立即购买</div>
        <div class="zongji">总计：{{amount.toFixed(2)}}元，{{num}}件</div>
      </div>
    </div>
    <van-action-sheet v-model="show" :round="false" title="购物车">
      <div class="content" v-for="(item,index) in couponConfigDOList" :key="index">
        <van-row>
          <van-col span="12">
            <div>{{item.couponName}}</div>
            <div>{{item.sellPrice}}</div>
          </van-col>
          <van-col span="4" style="margin-top: 7px;">
            <span v-if="item.type == 1">代金券</span>
            <span v-if="item.type == 2">折扣券</span>
            <span v-if="item.type == 3">项目免费券</span>
            <span v-if="item.type == 4">配件免费券</span>
            <span v-if="item.type == 5">单次券</span>
            <span v-if="item.type == 6">套餐券</span>
          </van-col>
          <van-col span="8" style="margin-top: 6px;">
            <van-stepper
              class="my-swipe"
              v-model="item.buyCounts"
              @plus="addjia(item)"
              @minus="minusCount(item)"
              @overlimit="minusCount(item)"
              theme="round"
              button-size="24"
              disable-input
            />
          </van-col>
        </van-row>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import orderModel from "../../api/order";
import preventReClick from "../../assets/preventReClick";
export default {
  data() {
    return {
      show: false,
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      active: 0,
      couponConfigDOList: [],
      num: 0,
      amount: 0, ///合计金额
      form: {}
    };
  },
  created() {
    this.namelist();
    // this.onLoad()
  },
  methods: {
    //加入购物车
    addjia(items) {
      console.log(items);
      items.buyCounts += 1;
      this.num += 1;
      var f = false;
      var arr = this.couponConfigDOList;
      if (arr.length > 0) {
        for (var i in arr) {
          if (arr[i].id == items.id) {
            f = true;

            break;
          }
        }
        if (!f) {
          arr.push(items);
        }
      } else {
        console.log("走了这之间添加");
        arr.push(items);
      }

      this.couponConfigDOList = arr;
      this.addheji();
      console.log(this.couponConfigDOList);
    },
    //减购物车数量
    minusCount(items) {
      var arr = this.couponConfigDOList;

      for (var i in arr) {
        if (arr[i].id == items.id) {
          arr[i].buyCounts -= 1;
          this.num -= 1;
          if (arr[i].buyCounts <= 0) {
            this.removeByValue(arr, items.id);
          }
        }
      }
      this.couponConfigDOList = arr;
      this.addheji();
    },
    //删除购物车数组
    removeByValue(array, val) {
      for (var i = 0; i < array.length; i++) {
        if (array[i].id == val) {
          array.splice(i, 1);
          break;
        }
      }
    },
    //合计金额
    addheji() {
      var amount = 0;
      for (var i in this.couponConfigDOList) {
        amount +=
          this.couponConfigDOList[i].sellPrice *
          this.couponConfigDOList[i].buyCounts;
      }
      this.amount = amount;
    },
    //购买
    pay() {
      var _this = this;

      if (this.couponConfigDOList.length < 1) {
        this.$toast("请先选择购买的优惠券！");
        return;
      }
      var lis = {
        amount: this.amount,
        // amount: 0.01,
        couponConfigDOList: this.couponConfigDOList,
        customerId: this.userInfo.customerId,
        gid: this.shangjia.id,
        name: this.userInfo.realName,
        phone: this.userInfo.phone,
        uid: this.userInfo.id
      };
      orderModel.buycoupon(lis).then(res => {
        if (res.code == 200) {
          if (typeof WeixinJSBridge === "undefined") {
            // 微信浏览器内置对象。参考微信官方文档
            if (document.addEventListener) {
              document.addEventListener(
                "WeixinJSBridgeReady",
                _this.onBridgeReady(res.data),
                false
              );
            } else if (document.attachEvent) {
              document.attachEvent(
                "WeixinJSBridgeReady",
                _this.onBridgeReady(res.data)
              );
              document.attachEvent(
                "onWeixinJSBridgeReady",
                _this.onBridgeReady(res.data)
              );
            }
          } else {
            console.log("准备调用微信支付");
            _this.onBridgeReady(res.data);
          }
        }
      });
    },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign // 微信签名
        },
        function(res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast.success("购买卡券成功!");
            vm.amount = 0;
            vm.couponConfigDOList = [];
            vm.namelist();
            setTimeout(() => {
              vm.$router.go(-1);
            }, 1000);
          } else {
            vm.$toast("支付失败");
          }
        }
      );
    },
    tixiangqin(item) {
      this.$router.push({
        path: "tuoke_coupon_details",
        query: {
          couponNumber: item.couponNumber,
          couponName: item.couponName,
          useExplain: item.useExplain,
          validity: item.validity,
          startDate: item.startDate,
          validityDate: item.validityDate,
          sellPrice: item.sellPrice,
          sellRule: item.sellRule,
          uid: this.userInfo.id,
          isok: false
        }
      });
    },
    namelist() {
      const data = {
        gid: this.shangjia.id
      };
      orderModel
        .couponmall(data)
        .then(e => {
          this.form = e.data;
        })
        .catch(() => loading.clear());
    },
    qiehuan(row) {
      this.active = row;
      // this.onLoad();
    },
    back() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="less" scoped>
.hed_lis {
  height: 45px;
  background: #fff;
  border-bottom: 1px solid #ebedf0;
  position: fixed;
  top: 46px;
  width: 100%;
  z-index: 99;
}
.hhcc {
  float: left;
  font-size: 14px;
  color: #646566;
  width: 20%;
  text-align: center;
  line-height: 45px;
  position: relative;
  overflow: hidden;
}
.van-popup {
  width: 200px;
  height: 250px;
}
.tox {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  margin-right: 7px;
  vertical-align: middle;
}

.main {
  padding: 92px 10px 0 10px;
  overflow: hidden;
}
.list {
  /* height: 150rpx; */
  /* background: #fff; */
  margin-top: 10px;
  position: relative;
}
.list_a {
  overflow: hidden;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
.ylinqu {
  color: #e35049;
  font-weight: bold;
  margin-top: 1px;
}
.tupiao {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -40px;
  width: 80px;
  margin-left: 15px;
  z-index: 20;
}
.letzuo {
  color: #e35049;
  /* margin-left: 60rpx; */
  text-align: center;
  font-size: 13px;
  width: 30%;
}
.yh_img {
  width: 100%;
  //   height: 1px;
}
.ris {
  padding: 23px 20px 10px 0px;
  width: 65%;
}
.ris_a {
  overflow: hidden;
}
.fsz {
  font-size: 12px;
  color: #919191;
}
.h1 {
  color: #000;
  font-size: 15px;
  font-weight: bold;
      width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.cunsts {
  margin-top: 3px;
  color: red;
  border: 1px solid red;
  line-height: 16px;
  padding: 0 4px;
  border-radius: 8px;
  font-size: 12px;
  margin-right: 5px;
}

.cunts {
  color: #fff;
  background: #e35049;
  line-height: 20px;
  padding: 0 4px;
  border-radius: 7px;
  font-size: 11px;
}
.acitv {
  color: #e35049;
  font-weight: bold;
  font-size: 15px;
}
.ctouxiang {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  border-radius: 8px;
  margin-right: 6px;
}
.botts {
  position: fixed;
  bottom: 0;
  height: 70px;
  // background: pink;
  width: 100%;
}
.bost {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  height: 45px;
  overflow: hidden;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.liji {
  width: 110px;
  float: right;
  background: #e35049;
  font-size: 18px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #fff;
}
.zongji {
  float: right;
  height: 45px;
  line-height: 45px;
  // width: 150px;
  font-size: 16px;
  color: #666;
  margin-right: 15px;
}
.gou {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  text-align: center;
  margin-left: 10px;
}
.gou2 {
  background: #e35049;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  margin-left: 10px;
  border: 2px solid #fff;
}
.shul {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  margin-right: 2px;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  background: #fff;
  color: red;
  font-weight: 600;
  line-height: 22px;
  border: 1px solid #e35049;
}
.gouwu {
  width: 35px;
  display: block;
  margin: auto;
  margin-top: 14px;
}
.riss {
  margin-top: 1px;
}
.jinse {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 16px;
  color: red;
  margin-left: 20px;
  line-height: 22px;
  font-weight: 600;
  width: 35px;
  text-align: center;
}
.kajin {
  height: 20px;

  margin-right: 10px;
}
.jianes {
  float: right;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  font-size: 24px;
  text-align: center;
}
.jianes img {
  width: 24px;
}
.my-swipe {
  float: right;
  -webkit-transform: rotate(0deg);
}
.van-action-sheet {
  width: 100%;
  font-size: 13px;
}
.van-action-sheet__header {
  background: #e35049;
  color: #fff;
  line-height: 40px;
  height: 40px;
}
.van-col {
  line-height: 16px;
}
.content {
  overflow: hidden;
  padding: 7px 15px;
  border-bottom: 1px solid #dcdcdc;
}
</style>
<style >
.van-stepper__input {
  width: 15px;
}
.content .van-stepper__input {
  width: 20px;
}
</style>